import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { setCookie } from 'utils/cookie'

const CLIENT_ID = '714402863795-4erefe28dp5uclnbant1o3eokootn2e8.apps.googleusercontent.com'
const REDIRECT_URI = `${window.location.origin}/callback`

const Callback = () => {
  const history = useHistory()
  useEffect(() => {
    const handleCode = () => {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')
      if (code) {
        fetch('https://www.googleapis.com/oauth2/v4/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `code=${code
          }&client_id=${CLIENT_ID}`
            + '&client_secret=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'
            + `&redirect_uri=${REDIRECT_URI}`
            + '&grant_type=authorization_code',
        })
          .then(response => response.json())
          .then((data) => {
            fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
              headers: {
                'Authorization': `Bearer ${data.access_token}`,
              },
            })
              .then(response => response.json())
              .then((userInfo) => {
                console.log(`Welcome, ${userInfo.name}! Your email is ${userInfo.email}.`)

                if (userInfo.email.endsWith('@yile.com.tw')) {
                  setCookie('oauth_code', code)
                  setCookie('email', userInfo.email)
                  history.push('/index')
                } else {
                  history.push('/login')
                }
              })
          })
      } else {
        console.log('驗證 code 失敗')
        history.push('/login')
      }
    }

    handleCode()
  }, [history])

  return (
    <div id="userInfo" />
  )
}

export default React.memo(Callback)
