import React from 'react'
import { StylesProvider } from '@mui/styles'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import store from './redux/store'
import * as serviceWorker from './serviceWorker'
import App from './App'

createRoot(document.getElementById('root')).render(
  <StylesProvider injectFirst>
    <Provider store={store}>
      <CssBaseline />
      <App />
    </Provider>
  </StylesProvider>
)
serviceWorker.unregister()
