import React, {
  useState, useEffect, useCallback, useMemo
} from 'react'
import PropTypes from 'prop-types'
import StopIcon from '@mui/icons-material/Stop'
import { Button } from '@mui/material'
import styles from './typingEffect.module.sass'

const TypingEffect = (props) => {
  const [displayedText, setDisplayedText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isPaused, setIsPaused] = useState(false)

  const {
    content, action, setAction, setStopData, index,
  } = props
  const [hide, setHide] = useState(false)

  const renderedContent = action ? displayedText : content

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused && currentIndex < content.length) {
        setHide(false)
        setDisplayedText(prevContent => prevContent + content[currentIndex])
        setCurrentIndex(prevIndex => prevIndex + 1)
      } else {
        setAction(false)
        setHide(true)
        clearInterval(interval)
      }
    }, 60) // 每60毫秒显示一个字

    return () => clearInterval(interval)
  }, [content, currentIndex, isPaused, setAction])

  const handlePause = useCallback((item) => {
    setIsPaused(true)
    setAction(prev => false)
    setStopData((prev) => {
      prev.push({ index, stopIndex: currentIndex })
      return [...prev]
    })
    setCurrentIndex(prevIndex => prevIndex) // 函数式更新 currentIndex
  }, [currentIndex, index, setAction, setStopData])

  const stopBtn = useMemo(() => (action && !hide) && (
    <Button onClick={handlePause} type="button" className={styles.icon}><StopIcon /></Button>
  ), [action, handlePause, hide])

  const parseContent = (data) => {
    if (!data) {
      return ''
    }
    const pdfRegex = /(https?:\/\/[^\s]+\.pdf)/g // 匹配以 .pdf 结尾的 URL
    const dataSplit = data.split(pdfRegex).map((part) => {
      if (part.match(pdfRegex)) {
      // 如果是以 .pdf 结尾的 URL，则转换为 <a> 标签
        const url = part
        const fileName = url.split('/').pop().split('%20').join(' ') // 提取文件名
        return (
          `<a href=${url} target="_blank" rel="noopener noreferrer">${fileName}</a>`
        )
      }
      // 否则替换换行符为 <br>，并去掉前后的逗号
      return part.replace(/(^,)|(,$)/g, '').replace(/\n/g, '<br>')
    })
    const result = dataSplit.join('')
    return result
  }

  return (
    <div className={styles.root}>
      {/* eslint-disable-next-line react/no-danger, max-len */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: parseContent(renderedContent) }} />
      {stopBtn}
    </div>
  )
}

TypingEffect.propTypes = {
  content: PropTypes.string.isRequired,
  action: PropTypes.bool,
  index: PropTypes.number.isRequired,
  setAction: PropTypes.func.isRequired,
  setStopData: PropTypes.func.isRequired,
}

TypingEffect.defaultProps = {
  action: false,
}

export default TypingEffect
