/* eslint-disable no-alert */
import React, {
  useEffect, useCallback, useState, useMemo, useRef
} from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import LogoutIcon from '@mui/icons-material/Logout'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import ForumIcon from '@mui/icons-material/Forum'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import StopIcon from '@mui/icons-material/Stop'
import RefreshIcon from '@mui/icons-material/Refresh'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { v4 as uuid } from 'uuid'
import TypingEffect from 'components/typingEffect'
import { getCookie, removeCookie } from 'utils/cookie'
import styles from './home.module.sass'

const titleData = {
  name: '聚寶知識庫',
  background: '#b7744e',
}
const menuList = [{
  id: 1,
  title: '聚寶AI',
  url: `${process.env.REACT_APP_API_URI}/answers/llm`,
}]

const Home = () => {
  // const dispatch = useDispatch()
  const history = useHistory()

  const [messageList, setMessageList] = useState([])
  // const [messageNumber, setMessageNumber] = useState(-1)
  const [question, setQuestion] = useState('')
  const [email, setEmail] = useState('')
  const [action, setAction] = useState(false)
  const [wait, setWait] = useState(false)
  const [stopData, setStopData] = useState([])
  const [actionIndex, setActionIndex] = useState(null)
  const [openMenu, setOpenMenu] = useState(false)

  const [menu, setMenu] = useState(1) //

  const messageListRef = useRef(null) // 創建一個 ref 來引用 messageList 元素
  useEffect(() => {
    if (menu) {
      setMessageList([])
      setStopData([])
      setActionIndex(null)
      setAction(false)
      setWait(false)
    }
  }, [menu])

  // 檢查登入狀態
  const checkLogin = useCallback(() => {
    const oauthCode = getCookie('oauth_code')
    const getMail = getCookie('email')
    if (getMail) {
      setEmail(getMail)
    }
    if (oauthCode) { // 存在表示登入成功
      return oauthCode
    }

    // 如果不存在 'oauth_code' cookie，清除cookie+導向到登錄頁面
    removeCookie('oauth_code')
    removeCookie('email')
    history.push('/login')
    // 添加默認返回值，這裡可以是 null 或者其他值，根據您的需要
    return null
  }, [history])
  // 登出
  const handleLogoutClick = useCallback(() => {
    // 清空消息列表
    setMessageList([])

    // 將所有 cookie 清除
    removeCookie('oauth_code')
    removeCookie('email')

    // 檢查登入狀態
    checkLogin()
  }, [checkLogin])

  // 檢查登入狀態
  useEffect(() => {
    checkLogin()
  }, [checkLogin])

  const scrollDown = useCallback(() => {
    messageListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }, [])
  useEffect(() => {
    scrollDown()
  }, [scrollDown])
  useEffect(() => {
    let timerId

    const handleScroll = () => {
      if (action) {
        scrollDown() // 执行滚动函数
        timerId = setTimeout(handleScroll, 100) // 每秒执行一次滚动函数
      }
    }

    handleScroll() // 初始调用一次滚动函数

    return () => {
      clearTimeout(timerId) // 清除定时器
    }
  }, [action, scrollDown])

  // 初始化聊天列表
  /* useEffect(() => {
    const testData = []
    testData.push(
      {
        question: '你好嗎', answer: '測試1', action: false, questionView: '你好嗎',
      }
    )
    setMessageList(testData)
  }, []) */

  // 清除聊天
  const handleClearMessage = useCallback(() => {
    setMessageList([])
    setAction(false)
    setWait(false)
    setActionIndex(null)
  }, [])

  // 停止
  const handleStop = useCallback(async () => {
    console.log('stopTop', actionIndex)
    if (actionIndex) {
      setMessageList((prev) => {
        prev[actionIndex].answer = prev[actionIndex].answer ? prev[actionIndex].answer : ''
        prev[actionIndex].action = false
        prev[actionIndex].stop = !prev[actionIndex].answer
        if (!prev[actionIndex].answer) {
          setStopData((prev2) => {
            prev2.push({ index: actionIndex, stopIndex: 0 })
            return [...prev2]
          })
        }
        return [...prev]
      })
    }

    setAction(false)
    setWait(false)
  }, [actionIndex])
  // 按讚
  const likeAnswer = useCallback(async (index) => {
    console.log('like', index)
    alert('功能尚未開放')
  }, [])
  // 倒讚
  const notLikeAnswer = useCallback(async (index) => {
    console.log('not like', index)
    alert('功能尚未開放')
  }, [])

  // 重問
  const refreshQuestion = useCallback(async (oldIndex, oldQuestion) => {
    setAction(true)
    setWait(true)
    setActionIndex(oldIndex)
    setMessageList(prev => prev.map((item, index) => ({
      ...item,
      action: index === oldIndex,
      answer: index === oldIndex ? null : item.answer,
      stop: index === oldIndex ? false : item.stop,
    })))
    setStopData((prev2) => {
      prev2.filter(item => item.index !== oldIndex)
      const updatedPrev2 = prev2.filter(item => item.index !== oldIndex)
      return [...updatedPrev2]
    })

    // 依照選的menu id 抓 menuList url 進行 fetch
    fetch(`${menuList.find(item => item.id === menu).url}?question=${oldQuestion}&mail=${email}`).then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
      .then((data) => {
        setMessageList((prev) => {
          const updatedList = prev.map((item, index) => ({
            ...item,
            answer: index === oldIndex ? data.answer : item.answer, // 设置最后一个元素的 answer
            sources: index === oldIndex ? data.sources : item.sources,
            action: index === oldIndex, // 将最后一个元素的 action 设置为 true，其余设置为 false
          }))
          console.log(updatedList)
          return updatedList
        })
        setWait(false)
      })
      .catch((error) => {
        // handleStop()
        alert('問題發生錯誤，請稍後再試')
        setMessageList((prev) => {
          const updatedList = prev.map((item, index) => ({
            ...item,
            status: index === oldIndex ? 0 : item.status,
          }))
          return updatedList
        })
        setActionIndex(null)
        setAction(false)
        setWait(false)

        console.error('There was a problem with your fetch operation:', error)
      })
  }, [email, menu])

  // 送出問題
  const handleSubmit = useCallback(async () => {
    if (question) {
      setAction(true)
      setWait(true)
      setActionIndex(messageList.length)

      const content = question.trim()
      const contentView = question.replace(/\n/g, '<br>')
      setQuestion('')

      setMessageList((prev) => { // 顯示問題
        if (prev.length > 0) {
          prev[prev.length - 1].action = false
        }
        const updatedList = [...prev, {
          question: content, answer: null, action: false, questionView: contentView, stop: false,
        }]
        console.log(updatedList)
        return updatedList
      })
      // 送出問題API
      fetch(`${menuList.find(item => item.id === menu).url}?question=${content}&mail=${email}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          // 輸出 API 回傳的訊息內容
          setMessageList((prev) => {
            const updatedList = prev.map((item, index) => ({
              ...item,
              answer: index === prev.length - 1 ? data.answer : item.answer, // 设置最后一个元素的 answer
              sources: index === prev.length - 1 ? data.sources : item.sources, //
              status: index === prev.length - 1 ? 1 : item.status, //
              action: index === prev.length - 1, // 将最后一个元素的 action 设置为 true，其余设置为 false
            }))
            console.log(updatedList)
            return updatedList
          })
          setWait(false)
        })
        .catch((error) => {
          // handleStop()
          alert('問題發生錯誤，請稍後再試')
          console.error('There was a problem with your fetch operation:', error)
          setMessageList((prev) => {
            const updatedList = prev.map((item, index) => ({
              ...item,
              status: index === prev.length - 1 ? 0 : item.status,
            }))
            return updatedList
          })
          setActionIndex(null)
          setAction(false)
          setWait(false)
        })

      return
    }
    alert('請輸入內容')
    // return null
  }, [email, menu, messageList.length, question])

  // 鍵盤監聽
  const handleKeyDown = (event) => {
    // 檢查按下的鍵盤按鍵是不是 Enter 鍵且沒有按下 Shift 鍵
    if (!action) {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault() // 防止換行
        handleSubmit() // 執行提交函數
      }
    }
  }

  const renderLinks = useCallback(sources => (
    <>
      {sources.map((source, index) => (
        <React.Fragment key={uuid()}>
          <a href={source} target="_blank" rel="noopener noreferrer">
            {source.split('/').pop()}
          </a>
          {index < sources.length - 1 && ', '}
        </React.Fragment>
      ))}
    </>
  ), [])

  // 將包含 HTML 標籤的字串轉換為 React 元素
  const renderHtmlString = htmlString => ({ __html: htmlString })
  const renderMessageList = useMemo(() => {
    const list = []
    console.log(78, messageList)
    messageList.forEach((val, index) => {
      const stop = stopData.find(el => el.index === index)
      if (val.status !== 0) {
        list.push(
          <div key={uuid()} className={styles.messageItem}>
            <div className={clsx(styles.message, styles.messageUser)}>
              <div className={styles.messageBox}>
                <span className={styles.messageSender}>You</span>
                <span className={styles.messageContent}>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={renderHtmlString(val.questionView)} />
                </span>
              </div>
            </div>

            <div className={clsx(styles.message, styles.messageOwner)}>
              <div className={styles.messageBox}>
                <span className={styles.messageSender}>AI</span>
                <span className={styles.messageContent}>
                  {(stop) ? (
                    <>

                      <div>{String(val.answer).substring(0, stop.stopIndex)}</div>
                      <div className={styles.buttonGroup}>
                        {/* 重問 */}
                        {(messageList.length - 1 === index) && (
                        <Button
                          className={clsx(styles.chatBtn, styles.refreshBtn)}
                          onClick={() => {
                            refreshQuestion(index, val.question)
                          }}
                        >
                          <RefreshIcon fontSize="small" />
                        </Button>
                        )}
                        {/* 按讚 */}
                        <Button
                          className={clsx(styles.chatBtn, styles.likeBtn)}
                          onClick={() => {
                            likeAnswer(index)
                          }}
                        >
                          <ThumbUpIcon fontSize="small" />
                        </Button>
                        {/* 倒讚 */}
                        <Button
                          className={clsx(styles.chatBtn, styles.notLikeBtn)}
                          onClick={() => {
                            notLikeAnswer(index)
                          }}
                        >
                          <ThumbDownIcon fontSize="small" />
                        </Button>
                        {val.sources && val.sources.length > 0 && (
                        <div className={styles.sources}>
                          出處：
                          { renderLinks(val.sources)}
                        </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <>

                      {(val.answer || val.stop) ? (
                        <>

                          <TypingEffect
                            content={String(val.answer)}
                            action={val.action}
                            index={index}
                            setAction={setAction}
                            setStopData={setStopData}
                          />
                          <div className={styles.buttonGroup}>
                            {/* 重問 */}
                            {(messageList.length - 1 === index) && (
                            <Button
                              className={clsx(styles.chatBtn, styles.refreshBtn)}
                              onClick={() => {
                                refreshQuestion(index, val.question)
                              }}
                            >
                              <RefreshIcon fontSize="small" />
                            </Button>
                            )}
                            {/* 按讚 */}
                            <Button
                              className={clsx(styles.chatBtn, styles.likeBtn)}
                              onClick={() => {
                                likeAnswer(index)
                              }}
                            >
                              <ThumbUpIcon fontSize="small" />
                            </Button>
                            {/* 倒讚 */}
                            <Button
                              className={clsx(styles.chatBtn, styles.notLikeBtn)}
                              onClick={() => {
                                notLikeAnswer(index)
                              }}
                            >
                              <ThumbDownIcon fontSize="small" />
                            </Button>
                            {val.sources && val.sources.length > 0 && (
                            <div className={styles.sources}>
                              出處：
                              { renderLinks(val.sources)}
                            </div>
                            )}
                          </div>
                        </>
                      )
                        : (
                          <div className={styles.loading}>
                            查詢中
                          </div>
                        )}
                    </>
                  )}

                </span>

              </div>

            </div>
          </div>
        )
      }
    })
    return list
  }, [messageList, stopData, renderLinks, refreshQuestion, likeAnswer, notLikeAnswer])
  // 監聽視窗大小
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setOpenMenu(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const renderBlack = useMemo(() => openMenu && (
  <Button
    className={styles.black}
    onClick={() => {
      setOpenMenu(prev => !prev)
    }}
  />
  ), [openMenu])

  return (
    <div className={clsx(styles.root, openMenu && styles.activeMenu)}>
      {renderBlack}
      <div className={styles.container}>
        <div className={styles.mobile}>
          <div className={styles.header}>
            <Button
              type="button"
              onClick={() => {
                setOpenMenu(prev => !prev)
              }}

            >
              <MenuIcon />
            </Button>
            <span className={styles.title}>
              {menuList.find(
                item => item.id === menu
              ).title}
            </span>
            <Button type="button">
              <HomeIcon />
            </Button>
          </div>
        </div>
        <div className={styles.menu}>
          {titleData !== null && (
            <div className={styles.title} style={{ background: titleData.background }}>
              {titleData.name}
            </div>
          )}
          <div className={styles.top}>
            <div className={clsx(styles.item, styles.itemChild)}>
              {menuList.map(item => (
                <Button
                  key={uuid()}
                  onClick={() => {
                    setMenu(item.id)
                  }}
                  className={clsx(menu === item.id && styles.active)}
                >
                  <ChatBubbleOutlineIcon />
                  {item.title}
                </Button>
              ))}

            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.item}>
              <Button onClick={handleClearMessage}>
                <DeleteIcon />
                清除聊天
              </Button>
            </div>

            <div className={styles.item}>
              <Button onClick={handleLogoutClick}>
                <LogoutIcon />
                登出
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.chatWrapper}>
            <div className={styles.messageIntro}>
              <h1 className={styles.title}>
                {menuList.find(
                  item => item.id === menu
                ).title}
              </h1>
            </div>
            {/* loading效果 */}
            <div className={clsx(styles.loadingOverlay, styles.hidden)}>
              <div className={styles.loadingSpinner} />
            </div>
            {/* !loading效果 */}
            <div className={clsx(styles.messageList, action && styles.action)} id="message-list">
              {renderMessageList}
              <div ref={messageListRef} className={styles.messageListRef} />
            </div>
            <div className={styles.chatBox}>
              <div className={styles.messageInput}>
                <textarea
                  id="message-input"
                  type="text"
                  placeholder="請輸入內容..."
                  rows="2"
                  value={question}
                  onChange={e => setQuestion(e.target.value)}
                  onKeyPress={handleKeyDown}
                />
                {wait ? (
                  <Button
                    className={styles.button}
                    color="inherit"
                    size="small"
                    title="Send"
                    onClick={handleStop}
                  >
                    <StopIcon />
                  </Button>

                ) : (
                  <Button
                    className={styles.button}
                    color="inherit"
                    size="small"
                    title="Send"
                    onClick={handleSubmit}
                    disabled={action}
                  >
                    <ForumIcon />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
