import React, {
  Suspense

} from 'react'
import './App.sass'
import { Router } from 'react-router-dom'
import history from 'router/history'
import MainView from './components/mainView'

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Suspense fallback={<div />}>
          <MainView />
        </Suspense>
      </Router>
      <Suspense fallback={<div />} />
    </div>
  )
}

export default App
